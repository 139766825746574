:root {

  --tittle-section1: #ece8e2;
  --tittle2-section1: #fca311;
  --subtittle-section1: #ece8e2;
  --background-section2: #ece8e2;

  --tittle-section2: #020202;
  --subtittle-section2: #101010;
  --background-section3: #ece8e2;
  --tittle-section3: #14213D;
  --subtittle-section3: #14213D;
  --tittle-section4: #fca311;
  --subtittle-section4: #fca311;
}

h1, h2, h3, h4, h5, h6,button{
  font-family: 'Sui-Generis', sans-serif;
}

p {
  font-family: 'Poppins', sans-serif;
}


.section1 {
  display: grid;
  grid-template-areas: "section3-text-area section3-image-area";
  grid-template-columns: 1fr 1fr;
  background-color: var(--background-section3);  

}

.section1-text-area {
  grid-area: section3-text-area;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.section1-title1 {
  color: var(--tittle-section3);
  font-family: 'GrandHotel', sans-serif;
}

.section1-title2 {
  color: var(--tittle-section3);
  font-family: 'GrandHotel', sans-serif;
}
  

.section1-subtitle {
  font-size: 1.2em;
  color: var(--subtittle-section3);
}

.section1-image-area {
  grid-area: section3-image-area;
  display: flex;
  justify-content: center;
}

.section2 {
  background-color: var(--background-section2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  display: grid;
  grid-template-areas: "section3-text-area section3-image-area";
  grid-template-columns: 1fr 1fr;
  gap: 20px;

}


.section2-title {
  color: var(--tittle-section2);
  font-family: 'LinLibertine', sans-serif;
}

.section2-subtitle {
  font-size: 1.2rem;
  color: var(--subtittle-section2);
  font-family: 'GrandHotel', sans-serif;
}


.section6-text-area {
  grid-area: section3-text-area;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section6-title1 {
  color: var(--tittle-section3);
  font-family: 'GrandHotel', sans-serif;
}


.section6-image-area {
  grid-area: section3-image-area;
  display: flex;
  justify-content: center;
}





/* Imágenes adaptables */



/* ============================= */
/* ========= MODAL ============ */
/* ============================= */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
}

.modal-content img {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0,0,0,0.4);
}

/* Evita scroll del fondo cuando el modal está abierto */
body.modal-open {
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 2rem;
  background: transparent;
  border: none;
  color: #ece8e2;
  cursor: pointer;
  z-index: 10000;
  transition: transform 0.2s ease;
}

.close-button:hover {
  transform: scale(1.2);
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0px;
  padding: 1px 2px;
}

.video-item {
  width: 100%;
}

.mosaic-video {
  width: 100%;
  height: auto;
  border-radius: 22px;

  background-color: #adaaa900;
}




/* Para pantallas grandes */
@media (min-width: 1401px) {
  .carousel-row {
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0px 0px;
    margin: 0 0;              /* Centrado automático */
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    background-color: #b1b0b000;
  }

  .all-sections-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
  }
  

  .section1 {
    display: flex;
    grid-template-areas: "section1-content section1-image-area";
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    padding: 20px 50px;
    border: 5px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    justify-content: center;
    height: 500px;
    
    box-sizing: border-box;    /* incluye padding/border en el ancho total */
  }

  .section1-content {

    gap: 0px;
    padding: 10px 30px;
    margin: 0 30px;
    max-height: 400px;
    min-height: 450px;
    max-width: 600px;
    min-width: 580px;
    height: 100%;
  }

.three-columns {
  display: flex;
  justify-content: center;
  max-width: 600px;
  min-width: 580px;
  padding: 10px 30px;
  margin: 0;
  
}


.section1-title {
  font-family: 'GrandHotel', sans-serif;
  font-size: 5.0em;
  margin-right: 10px;
  text-align: left;
  padding: 0px 0px;
  line-height:100%;
  font-weight: lighter;
}


.hashtaggg {
  color: #e5360b;
  padding: 0px;
  transform: rotateX(180deg);
  font-size: 2em;
  font-weight: bold;
  line-height: 0cap;
  padding: 0px 0px;
  display:inline-flex;
  vertical-align: super; /* Asegura alineación con el texto */
}

.indent-text {
  font-family: 'GrandHotel', sans-serif;
  padding: 0px 0px;
}

.hashtaggg2 {
  font-weight: bold;
  color: #e5360b;
  transform: rotate(180deg);
  font-size: 2.0em;
  line-height: 0cap;
  padding: 0px 0px;
  display:inline-flex;
  vertical-align:top; /* Asegura alineación con el texto */
}

  .section1-subtitle {
    font-size: 1.3em;
    margin-right: 0px;
    text-align: end;
    padding: 0px;

  }

  .section1-image-area {
    display: block;
    width: auto;
    height: auto;
    flex: 1;
    display: flex;
    margin: 0;
    padding: 0;
}

.section1-image {
  max-height: 500px;
  min-height: 420px;
  max-width: 690px;
  min-width: 660px;
  margin-top: -50px;
}


.section2 {
  background-color: var(--background-section2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  display: grid;
  grid-template-areas: "section3-text-area section3-image-area";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border: 5px solid black;
  border-bottom: 1px solid black;

}

.section2-text-area {
  padding: 0px 0px;
  max-width: 650px;
  margin-left: 50px;
}

.hashtag {
  color: #e4340a;
  padding: 0px;

}

.hashtag2 {
  color: #e4340a;
  padding: 0px;

  font-family: 'LinLibertine', sans-serif;

}

.section2-subtitle {
  font-size: 5em;
  padding: 0px;
  line-height: 100%;

}

.section2-title {
  font-family: 'LinLibertine', sans-serif;
  font-size: 5em;
  padding: 0px;
}

.section2-image {
  width: 600px;
  margin-right: 50px;
}

.section3 {
  width: 100%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  border: 5px solid black;
  border-bottom: 1px solid black;
  background-color: var(--background-section2);
}



.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.section4 {
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0px solid black;
  border-bottom: 1px solid black;
  border-top: 0px solid black;
  background-color: #ece8e2;
}

.first-row {
  display: flex;
  margin: 0;
  height: 25vh;
  padding: 0;
  border-right: 5px solid black;
  background-color: #ece8e2;

}

.column1 h1 {
  font-size: 5.4rem;
  font-family: 'LinLibertine', sans-serif;
}

.column1 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 80%;
  margin: 0;
  padding: 0px 0px;

}

.column2 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 100%;
  word-wrap: break-word; /* Evita que las palabras largas se salgan */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Centra el bloque */
  text-align: left;
}

.column2 h2 {
  font-size: 3.8rem;
  font-family: 'LinLibertine', sans-serif;
  margin-top: 0px;
  justify-content:start;

}

.second-row {
  margin-top: 0px;
  border: 5px solid black;
  border-bottom: 0px solid black;
  padding: 0px 200px;
}

.second-row h3 {
  text-align: center;
  font-size: 1.5rem;
  padding: 5px 50px;
  margin-top: 30px;
  font-family: 'GrandHotel', sans-serif;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 55px;
  margin-top: 0px;
}

.social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  padding: 0;
  background-color: #e5360b;
  color: #ece8e2; /* Color de los iconos */
  transition: transform 0.3s ease, background-color 0.3s ease;
}


.social-icons a svg {
  width: 205px; /* Tamaño del icono dentro del círculo */
  height: 95px
}


.section5 {
  background-color: #ece8e2;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 5px solid rgb(0, 0, 0);
  border-bottom: 0px solid rgb(0, 0, 0);
}

.title-row {
  justify-content: center;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 4px solid rgb(0, 0, 0);
}

.title-row h2 {
  font-size: 3rem;
  margin: 0px 0px;
  padding: 0px;
}

.title-row h1 {
  font-family: 'LinLibertine', sans-serif;
  font-size: 5.5rem;
  padding: 25px 80px;
}



.video-row {
  border-bottom: 0px solid rgb(0, 0, 0);
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
}

.video-row .column1 {
  width: 50%;
  margin: 0;
  padding: 0;
  border-left: 0px solid rgb(0, 0, 0);
  border-bottom: 0px solid rgb(0, 0, 0);
}

.video-row .column2 {
  border-top: 5px solid rgb(0, 0, 0);
  border-bottom: 00px solid rgb(0, 0, 0);

  width: 50%;
  margin: 0;
  padding: 0;
}

video {
  width: 100%;
  height: auto;
}

.section6 {

  border-top: 0px solid rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #d13e214c;
  position: relative; /* Permite posicionar elementos absolutos dentro */
  height: auto; /* Mantiene la sección con altura de pantalla */
  overflow: hidden; /* Evita que elementos se salgan de la sección */
}


.first-row1 {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  border-right: 5px solid black;
  border-top: 0px solid rgb(0, 0, 0);
  justify-content: center;
  text-align: center;

}

.column22 h2 {
  font-size: 4rem;
  text-align: justify;
  font-family: 'LinLibertine', sans-serif;
  
}

.column11 h1 {
  font-size: 5.5rem;
  font-family: 'LinLibertine', sans-serif;
  padding: 50px;
  margin: 0;
  background-color: #9c6f1000;

}

.column11 {
  background-color: #ece8e2;
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 80%;
  margin: 0;
  padding: 0;
  
}

.column22 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 100%;
  margin: 0;
  padding: 0px 70px;
  background-color: #e5360b;
}



.second-row1 {
  border: 5px solid black;
  border-bottom: 5px solid black;
  display: flex;

  align-items: center;
  justify-content: space-between;
  background-color: #ece8e2;
  gap: 0px; /* opcional, espacio entre bloques */
}

.left-block {
  margin-left: -30px ;
  padding: 0px 0px;
  flex: 0 0 auto;
  background-color: #ece8e2;
  justify-content: start;
  left: 0px;
}

.left-block p {

  margin: 0;
  font-family: 'LinLibertine', sans-serif;
  padding: 0px 0px;
  font-size: 3.2em;
  flex: 0 0 auto;
  background-color: #ece8e2;
}

.section6-image-area {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0px 0px;
}

.new-block {
  padding: 0px 0px;
  flex: 0 0 auto;
  background-color: #ece8e2;
}

.section6-image {
  padding: 0px 0px;
  background-color: #ece8e2;
  max-width: 320px;
  min-width: 200px;
  max-height: 900px;
  margin-left: -50px;
  display: block;
}


.contact-video {
  padding: 0px 0px;
  max-width: 100%;
  height: 120px;
  display: block;
}




.carousel-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 0;
  margin: 0;
  overflow: hidden;
}

.carousel {
  width: 100%;
  padding: 0px 0;
  margin: 0;
}

.swiper-slide {
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
  padding: 0px 0;
  margin: 0;
}

/* Imagen ocupa el marco fijo */
.swiper-slide img {
  width: auto;
  padding: 0px 0;
  margin: 0;
}

.carousel-img {
  object-fit: cover;
  max-height: 800px;
  padding: 0px 0;
}


.carousel-img:hover {
  transform: scale(1.02);
}

}

@media ((min-width: 501px) and (max-width: 1100px)) {

  .all-sections-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
    border: 2px solid black;
  }
  
    /* SECCIÓN 1 */
    .section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0px;
      border: none;
      height: auto;
      border-bottom: 2px solid black;
    }
  
    .section1-text-area {
      text-align: left;
      padding: 0px;
    }
  
    .section1-title {
      font-family: 'GrandHotel', sans-serif;
      font-size: 3.8rem;
      margin-right: 10px;
      text-align: left;
      padding: 0px 0px;
      line-height:100%;
      font-weight: lighter;
    }


    
    
    .hashtaggg {
      color: #e5360b;
      padding: 0px;
      transform: rotateX(180deg);
      background-color: #dfba6992;
      font-size: 2em;
      font-weight: bold;
      line-height: 0cap;
      padding: 0px 0px;
      display:inline-flex;
      vertical-align: super; /* Asegura alineación con el texto */
    }
    
    .indent-text {
      font-family: 'GrandHotel', sans-serif;
      padding: 0px 0px;
    }
    
    .hashtaggg2 {
      font-weight: bold;
      color: #e5360b;
      transform: rotate(180deg);
      font-size: 2.0em;
      line-height: 0cap;
      padding: 0px 0px;
      display:inline-flex;
      vertical-align:top; /* Asegura alineación con el texto */
    }
    .section1-image-area {
      width: 100%;
      justify-content: center;
    }
  
    .section1-image {
      width: 80%;
      height: auto;
    }
  
    /* SECCIÓN 2 */
    .section2 {
      border-bottom: 2px solid black;
      border-top: 2px solid black;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 10px;
      gap: 0px;
      border: none;
    }
  
    .hashtag {
      color: #e5360b;
      padding: 0px;
    
    }
    .section2-title {
      font-size: 3.6rem;
      font-weight: lighter;

    }
  
    .section2-subtitle {
      font-size: 3.3rem;    }
  
    .section2-image {
      width: 100%;
      height: auto;
      display: none; /* Oculta todo excepto el video */

    }
  
    /* SECCIÓN 3 - VIDEO */
    .section3 {
      border-bottom: 2px solid black;
      border-top: 2px solid black;
      width: 100%;
      height: auto;
      position: relative;
      padding: 0px;
    }
  
    .background-video {
      padding: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    /* SECCIÓN 4 */
    .section4 {
      border-bottom: 2px solid black;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 5px 0px;
      background-color: #ece8e2;
    }



    .social-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-top: 0px;
      padding: 20px 50px;
      background-color: #21d14a00;
      width: 100%;
      flex-wrap: wrap;
      
    }

    .social-icons a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px; /* Tamaño del círculo ajustado */
      height: 42px;
      padding: 0px 0px;
      margin: 0;
      border-radius: 50%;
      background-color: #e5360b;
      color: #ece8e2; /* Color de los iconos */

    }
    
    .social-icons a:hover {
      padding: 0px 0px;
      margin: 0;
      background-color: darkorange;
    }
    
    .social-icons a svg {
      width: 27px; /* Tamaño del icono dentro del círculo */
      height: 100%;
      padding: 0px 0px;
      margin: 0;
    }
  
    .first-row {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0px 10px;
    }
  
    .column1 h1 {
      font-size: 3.6rem;
      font-weight: lighter;
      font-family: 'LinLibertine', sans-serif;

    }
  
    .column2 h2 {
      font-size: 2.3rem;
      text-align: center;
      padding: 0px 50px;
      font-weight: lighter;
    }

    .second-row h3{
      padding: 10px 25px;
      font-size: 1.5rem;
      text-align: left;
      font-weight: lighter;
      font-family: 'GrandHotel', sans-serif;
    }

    .section5 {
      border-bottom: 2px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0px 0px;
      background-color: #ece8e2;
    }



    .title-row h2 {
      font-size: 1rem;
      margin: 0px 0px;
      padding: 0px;
    }
    
    .title-row h1 {
      font-size: 3.5rem;
      font-weight: lighter;
      padding: 5px 50px;
      width: auto;
      font-family: 'LinLibertine', sans-serif;
    }


.section6 {
  background-color: #ece8e2;
  border-bottom: 0px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 0px;
}

.first-row1 {
  border-bottom: 2px solid black;
  width: 100%;
}

.column11 h1 {
  font-family: 'LinLibertine', sans-serif;
  font-size: 3.5rem;
  font-weight: lighter;
  padding: 0px;
  
}

.column22 h2 {
  font-size: 3.4rem;
  padding: 0px;
}

.second-row1 {
  display: flex;
  flex-direction: column;
  height: auto;
}

.left-block {
  width: 100%;
  text-align: center;
}

.left-block-column p {
  font-size: 2.5rem;
  font-family: 'LinLibertine', sans-serif;
}

.section6-image {
  width: 75%;
  height: auto;

}

/* VIDEO */
.video-row {

  flex-direction: column;
}

.video-row .column1,
.video-row .column2 {
  width: 100%;
}

video {

  width: 100%;
  height: auto;
}

.contact-video {
  display: none;
  width: 100%;
  height: auto;
}

.carousel-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 0;
  margin: 0;
  overflow: hidden;
}

.carousel {
  width: 100%;
  padding: 0px 0;
  margin: 0;
}

.swiper-slide {
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
  padding: 0px 0;
  margin: 0;
}

/* Imagen ocupa el marco fijo */
.swiper-slide img {
  width: auto;
  padding: 0px 0;
  margin: 0;
}

.carousel-img {
  object-fit: cover;
  max-height: 400px;
  padding: 0px 0;
  margin: 0;
}


.section6-image {
  width: 55%;
  height: auto;
  padding: 0px 0;

}



.video-grid {
  grid-template-columns: repeat(1, 1fr);
}
  
}



@media ((min-width: 1101px) and (max-width: 1400px)) {

  .carousel-row {
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0px 0px;
    margin: 0 0;              /* Centrado automático */
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    background-color: #b1b0b000;
  }

  .all-sections-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
  }
  

  .section1 {
    display: flex;
    grid-template-areas: "section1-content section1-image-area";
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    padding: 20px 00px;
    border: 5px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    justify-content: center;
    height: 500px;
    

  }

  .section1-content {
    background-color: #dc878700; /* Color de fondo del cuadro */

    gap: 0px;
    padding: 10px 0px;
    margin-left: 20px;
    max-height: 400px;
    min-height: 450px;
    max-width: 600px;
    min-width: 580px;
    height: 100%;
  }

.three-columns {
  display: flex;
  justify-content: center;
  max-width: 600px;
  min-width: 580px;
  padding: 10px 30px;
  margin: 0;
  
}


.section1-title {
  font-family: 'GrandHotel', sans-serif;
  font-size: 4.5rem;
  margin-right: 10px;
  text-align: left;
  padding: 0px 0px;
  line-height:100%;
  font-weight: lighter;
}


.hashtaggg {
  color: #e5360b;
  padding: 0px;
  transform: rotateX(180deg);
  background-color: #dfba6992;
  font-size: 2em;
  font-weight: bold;
  line-height: 0cap;
  padding: 0px 0px;
  display:inline-flex;
  vertical-align: super; /* Asegura alineación con el texto */
}

.indent-text {
  font-family: 'GrandHotel', sans-serif;
  padding: 0px 0px;
}

.hashtaggg2 {
  font-weight: bold;
  color: #e5360b;
  transform: rotate(180deg);
  font-size: 2.0em;
  line-height: 0cap;
  padding: 0px 0px;
  display:inline-flex;
  vertical-align:top; /* Asegura alineación con el texto */
}

  .section1-subtitle {
    font-size: 1.3em;
    margin-right: 0px;
    text-align: end;
    padding: 0px;

  }

  .section1-image-area {
    display: block;
    width: auto;
    height: auto;
    background-color: #87dc9400;
    flex: 1;
    display: flex;
    margin: 0px 00px;
    padding: 0 0px;
}

.section1-image {
  margin-left: -100px;
  padding: 10px 0px;
  max-height: 400px;
  min-height: 350px;
  max-width: 500px;
  min-width: 480px;
  margin-top: -20px;
}


.section2 {
  background-color: var(--background-section2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  display: grid;
  grid-template-areas: "section3-text-area section3-image-area";
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border: 5px solid black;
  border-bottom: 1px solid black;

}

.section2-text-area {
  padding: 0px 0px;
  max-width: 650px;
  margin-left: -150px;
}

.hashtag {
  color: #e5360b;
  padding: 0px;

}

.hashtag2 {
  color: #e5360b;
  padding: 0px;

  font-family: 'LinLibertine', sans-serif;

}

.section2-subtitle {
  font-size: 4.3rem;
  padding: 0px;
  line-height: 100%;

}

.section2-title {
  font-family: 'LinLibertine', sans-serif;
  font-size: 4.5rem;
  padding: 0px;
}

.section2-image {
  width: 550px;
  margin-left: -70px;
  margin-right: 100px;
}

.section3 {
  width: 100%;
  height: 50vh;
  overflow: hidden;
  position: relative;
  border: 5px solid black;
  border-bottom: 1px solid black;
  background-color: var(--background-section2);
}



.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.section4 {
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0px solid black;
  border-bottom: 1px solid black;
  border-top: 0px solid black;
  background-color: #ece8e2;
}

.first-row {
  display: flex;
  margin: 0;
  height: 25vh;
  padding: 0;
  border-right: 5px solid black;
  background-color: #ece8e2;

}

.column1 h1 {
  font-size: 4.5rem;
  font-family: 'LinLibertine', sans-serif;
}

.column1 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 45%;
  margin: 0;
  padding: 0px 0px;

}

.column2 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 100%;
  word-wrap: break-word; /* Evita que las palabras largas se salgan */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: left;
  padding: 0px 0px;
}

.column2 h2 {
  font-size: 3.1rem;
  font-family: 'LinLibertine', sans-serif;
  margin-top: 0px;
  justify-content:start;

}

.second-row {
  margin-top: 0px;
  border: 5px solid black;
  border-bottom: 0px solid black;
  padding: 0px 150px;
}

.second-row h3 {
  text-align: center;
  font-size: 1.5rem;
  padding: 5px 50px;
  margin-top: 30px;
  font-family: 'GrandHotel', sans-serif;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 45px;
  margin-top: 0px;
}

.social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  padding: 0;
  background-color: #e5360b;
  color: #ece8e2; /* Color de los iconos */
  transition: transform 0.3s ease, background-color 0.3s ease;
}


.social-icons a svg {
  width: 35px; /* Tamaño del icono dentro del círculo */
  height: 95px;
    padding: 0;
}


.section5 {
  background-color: #ece8e2;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 5px solid rgb(0, 0, 0);
  border-bottom: 0px solid rgb(0, 0, 0);
}

.title-row {
  justify-content: center;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 4px solid rgb(0, 0, 0);
}

.title-row h2 {
  font-size: 3rem;
  margin: 0px 0px;
  padding: 0px;
}

.title-row h1 {
  font-family: 'LinLibertine', sans-serif;
  font-size: 4.5rem;
  padding: 25px 30px;
}



.video-row {
  border-bottom: 0px solid rgb(0, 0, 0);
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
}

.video-row .column1 {
  width: 50%;
  margin: 0;
  padding: 0;
  border-left: 0px solid rgb(0, 0, 0);
  border-bottom: 0px solid rgb(0, 0, 0);
}

.video-row .column2 {
  border-top: 5px solid rgb(0, 0, 0);
  border-bottom: 00px solid rgb(0, 0, 0);

  width: 50%;
  margin: 0;
  padding: 0;
}

video {
  width: 100%;
  height: auto;
}

.section6 {

  border-top: 0px solid rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #d13e214c;
  position: relative; /* Permite posicionar elementos absolutos dentro */
  height: auto; /* Mantiene la sección con altura de pantalla */
  overflow: hidden; /* Evita que elementos se salgan de la sección */
}


.first-row1 {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  border-right: 5px solid black;
  border-top: 0px solid rgb(0, 0, 0);
  justify-content: center;
  text-align: center;

}

.column22 h2 {
  font-size: 4rem;
  text-align: justify;
  font-family: 'LinLibertine', sans-serif;
  
}

.column11 h1 {
  font-size: 4.5rem;
  font-family: 'LinLibertine', sans-serif;
  padding: 50px;
  margin: 0;
  background-color: #9c6f1000;

}

.column11 {
  background-color: #ece8e2;
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  width: 80%;
  margin: 0;
  padding: 0;
  
}

.column22 {
  border: 5px solid rgb(0, 0, 0);
  border-right: 1px solid black;
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 100%;
  margin: 0;
  padding: 0px 70px;
  background-color: #e5360b;}

  .second-row1 {
    background-color: #ece8e2;
    border: 5px solid black;
    border-bottom: 5px solid black;
    display: flex;

    align-items: center;
    justify-content: space-between;
    gap: 0px; /* opcional, espacio entre bloques */
  }
  
  .left-block {
    margin-left: -30px ;
    padding: 0px 0px;
    flex: 0 0 auto;
    background-color: #ece8e2;
    justify-content: start;
    left: 0px;
  }
  
  .left-block p {
  
    margin: 0;
    font-family: 'LinLibertine', sans-serif;
    padding: 0px 0px;
    font-size: 2.2em;
    flex: 0 0 auto;
    background-color: #ece8e2;
  }
  
  .section6-image-area {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0px 0px;
  }
  
  .new-block {
    padding: 0px 0px;
    flex: 0 0 auto;
    background-color: #ece8e2;
  }
  
  .section6-image {
    padding: 0px 0px;
    background-color: #ece8e2;
    max-width: 220px;
    min-width: 200px;
    max-height: 800px;
    margin-left: -10px;
    display: block;
  }
  
  
  .contact-video {
    max-width: 300px;
    min-width: 200px;
    padding: 0px 0px;

    height: 120px;
    display: block;
  }

.carousel-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 0;
  margin: 0;
  overflow: hidden;
}

.carousel {
  width: 100%;
  padding: 0px 0;
  margin: 0;
}

.swiper-slide {
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
  padding: 0px 0;
  margin: 0;
}

/* Imagen ocupa el marco fijo */
.swiper-slide img {
  width: auto;
  padding: 0px 0;
  margin: 0;
}

.carousel-img {
  object-fit: cover;
  max-height: 800px;
  padding: 0px 0;
}


.carousel-img:hover {
  transform: scale(1.02);
}
  
}



@media screen and (max-width: 500px) {
  
  .all-sections-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
    border: 2px solid black;
  }
  
    /* SECCIÓN 1 */
    .section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 5px;
      border: none;
      height: auto;


      border-bottom: 2px solid black;
    }
  
    .section1-text-area {
      text-align: left;
      padding: 0px;
    }
  
    .section1-title {
      font-family: 'GrandHotel', sans-serif;
      font-size: 2.5rem;
      margin-right: 10px;
      text-align: left;
      padding: 0px 0px;
      line-height:100%;
      font-weight: lighter;
    }


    
    
    .hashtaggg {
      color: #e5360b;
      padding: 0px;
      transform: rotateX(180deg);
      font-size: 2em;
      font-weight: bold;
      line-height: 0cap;
      padding: 0px 0px;
      display:inline-flex;
      vertical-align: super; /* Asegura alineación con el texto */
    }
    
    .indent-text {
      font-family: 'GrandHotel', sans-serif;
      padding: 0px 0px;
    }
    
    .hashtaggg2 {
      font-weight: bold;
      color: #e5360b;
      transform: rotate(180deg);
      font-size: 2.0em;
      line-height: 0cap;
      padding: 0px 0px;
      display:inline-flex;
      vertical-align:top; /* Asegura alineación con el texto */
    }
    .section1-image-area {
      width: 100%;
      justify-content: center;
    }
  
    .section1-image {
      width: 100%;
      height: auto;
      border: none;
      margin: 0;
      padding: 0;
      display: block;
      pointer-events: none;
    }

    video::-webkit-media-controls {
      display: none !important;
      -webkit-appearance: none;
    }
  
    /* SECCIÓN 2 */
    .section2 {
      border-bottom: 2px solid black;
      border-top: 2px solid black;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 10px;
      gap: 0px;
      border: none;
    }
  
    .hashtag {
      color: #e5360b;
      padding: 0px;
    
    }
    .section2-title {
      font-size: 2.5rem;
      font-weight: lighter;

    }
  
    .section2-subtitle {
      font-size: 2.4rem;
    }
  
    .section2-image {
      width: 100%;
      height: auto;
      display: none; /* Oculta todo excepto el video */
      pointer-events: none;
    }
  
    /* SECCIÓN 3 - VIDEO */
    .section3 {
      border-bottom: 2px solid black;
      border-top: 2px solid black;
      width: 100%;
      height: 30vh;
      position: relative;
      padding: 0px;
    }
  
    .background-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  
    /* SECCIÓN 4 */
    .section4 {
      border-bottom: 2px solid black;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10px 0px;
      background-color: #ece8e2;
    }



    .social-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 0px;
      padding: 10px 50px;
      background-color: #21d14a00;
      width: 100%;
      flex-wrap: wrap;
      
    }

    .social-icons a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px; /* Tamaño del círculo ajustado */
      height: 35px;
      border-radius: 50%;
      background-color: #e5360b;
      color: #ece8e2; /* Color de los iconos */
      transition: transform 0.3s ease, background-color 0.3s ease;
    }
    
    .social-icons a:hover {
      transform: scale(1.1);
      background-color: darkorange;
    }
    
    .social-icons a svg {
      width: 25px; /* Tamaño del icono dentro del círculo */
      height: 25px;
    }
  
    .first-row {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0px 10px;
    }
  
    .column1 h1 {
      font-size: 2.5rem;
      font-weight: lighter;
      font-family: 'LinLibertine', sans-serif;

    }
  
    .column2 h2 {
      font-size: 1.6rem;
      text-align: center;
      padding: 0px 50px;
      font-weight: lighter;
    }

    .second-row h3{
      padding: 10px 25px;
      font-size: 1.4rem;
      text-align: left;
      font-weight: lighter;
      font-family: 'GrandHotel', sans-serif;
    }

    .section5 {
      border-bottom: 2px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0px 0px;
      background-color: #ece8e2;
    }



    .title-row h2 {
      font-size: 1rem;
      margin: 0px 0px;
      padding: 0px;
    }
    
    .title-row h1 {
      font-family: 'LinLibertine', sans-serif;
      font-size: 2.5rem;
      font-weight: lighter;
      padding: 5px 50px;
      width: auto;
    }
  


.carousel-row {
  display: block;
  height: auto;
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 0px 0px;
  margin: 0 0;              /* Centrado automático */
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  background-color: #b1b0b000;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}


.carousel {
  width: 100%;
  max-width: 1000px;
}



.carousel-img:hover {
  transform: scale(1.02);
}

  
.carousel img {
  display: block;
  padding: 0px 0px;
  margin: 0;
  width: 100%;
  object-fit:cover;
  border-radius: 0px;

}

.swiper-slide {
  width: 100%;
  position: relative;
  aspect-ratio: 16 / 9; /* Proporción uniforme */
  overflow: hidden;
  border-radius: 10px;
}

/* Imagen ocupa el marco fijo */
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Recorta pero no deforma */
  display: block;
}

    /* SECCIÓN 6 */
.section6 {
  background-color: #ece8e2;
  border-bottom: 0px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 0px;
}

.first-row1 {
  border-bottom: 2px solid black;
  width: 100%;
}

.column11 h1 {
  font-size: 2.5rem;
  font-weight: lighter;
  font-family: 'LinLibertine', sans-serif;
  padding: 0px;
  
}

.column22 h2 {
  font-size: 2.4rem;
  padding: 10px;
}

.second-row1 {
  display: flex;
  flex-direction: column;
  height: auto;
}

.left-block {
  width: 100%;
  text-align: center;
}

.left-block-column p {
  font-size: 2rem;
  font-family: 'LinLibertine', sans-serif;
}

.section6-image {
  width: 65%;
  height: auto;
  border: none;
  margin: 0;
  padding: 0;
  display: block;
  pointer-events: none;

}

/* VIDEO */
.video-row {

  flex-direction: column;
}

.video-row .column1,
.video-row .column2 {
  width: 100%;
}

video {

  width: 100%;
  height: auto;
}

.contact-video {
  display: none;
  pointer-events: none;
  width: 100%;
  height: auto;
}

.video-grid {
  grid-template-columns: 1fr;
}
}
  


