@media (min-width: 1401px) {

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  background-color: #e5360b;
  border: 5px solid black;
  color: #efebe6;
}

.logo img {
  height: 50px;
}

.logo-video {
  padding: 0px 0px;
  height: 250px;
}

.navbar {
  background-color: #e5360b;
  text-align: left;
  display: flex;
  margin-left: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px;
}

.navbar button {
  font-family: 'GrandHotel', sans-serif;
  font-size: 1.9rem;
  color: #efebe6;
  text-decoration: none;
  text-align: left;
  padding: 1px;
  margin-left: 10px;
  display: block;
  background: none; 
  border: none; 
  outline: none; 
}

.navbar button:hover {
  color: #000000;
}

.right-section {
  padding: 10px 100px;
  background-color: #e5360b;
  display: flex;
  align-items: center;
  gap: 0px; 
  
}

.small-icon {
  background-color: #e5360b;
  height: 250px; /* Ajusta el tamaño de la imagen */
}

}



  @media ((min-width: 1101px) and (max-width: 1400px)) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    background-color: #e5360b;
    border: 5px solid black;
    color: #efebe6;
  }
  
  .logo img {
    height: 50px;
    
  }
  
  .logo-video {
    padding: 0px 0px;
    margin: 0;
    height: 200px;
  }
  
  .navbar {
    background-color: #e5360b;
    text-align: left;
    display: flex;
    margin-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    padding: 1px;
  }
  
  .navbar button {
    font-family: 'GrandHotel', sans-serif;
    font-size: 1.9rem;
    color: #efebe6;
    text-decoration: none;
    text-align: left;
    padding: 1px;
    margin-left: 10px;
    display: block;
    background: none; 
    border: none; 
    outline: none; 
  }
  
  .navbar button:hover {
    color: #000000;
  }
  
  .right-section {
    padding: 10px 100px;
    background-color: #e5360b;
    display: flex;
    align-items: center;
    gap: 0px; 
  }
  
  .small-icon {
    background-color: #e5360b;
    height: 250px; /* Ajusta el tamaño de la imagen */
  }
  
  }

  @media ((min-width: 501px) and (max-width: 1100px)) {

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    height: auto;
    background-color: #e5360b;
    border: 2px solid black;
    border-bottom: 0px solid black;
  }

 .navbar,
  .right-section {
    display: none; /* Oculta todo excepto el video */
  }

  .logo-video {
    height: auto; /* Ocupa toda la pantalla */
    width: 60vw; /* Asegura que cubra el ancho */
    object-fit: cover; /* Ajusta el video al tamaño */
  }
}

/* ESTILOS PARA MÓVIL (SOLO SE MUESTRA EL VIDEO) */
@media screen and (max-width: 500px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
    height: auto;
    background-color: #e5360b;
    border: 2px solid black;
    border-bottom: 0px solid black;
  }

 .navbar,
  .right-section {
    display: none; /* Oculta todo excepto el video */
  }

  .logo-video {

    height: auto; /* Ocupa toda la pantalla */
    width: 95vw; /* Asegura que cubra el ancho */
    object-fit: cover; /* Ajusta el video al tamaño */
  }
}
